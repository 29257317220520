import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ModalsContainer from '../components/Shared/Layouts/modals/ModalsContainer';
import { appHistory } from '../shared/utils/sharedHistory';
import { setCurrentUser } from '../store/actions/creators/registration';
import { connect } from 'react-redux';
import { RegistrationState } from '../@types/redux/appRessourceStates/registration';
import { NotFound, SuspenseSpinner, getTokens, Tokens } from '@mola/client-components';

const Home = lazy(() => import('../pages/Home'));
const ComingSoon = lazy(() => import('../pages/Home/ComingSoon'));

const Routes: React.FC<RoutesProps> = ({ currentUser, _setCurrentUser, isAuth }) => {
  const storageTokens = getTokens();

  const foundTokens = storageTokens && storageTokens.tokens.access && storageTokens.tokens.refresh;

  if (!currentUser && foundTokens && storageTokens.storage === 'localStorage') {
    _setCurrentUser(storageTokens.tokens, true, 'localStorage');
  } else if (!isAuth && foundTokens && storageTokens.storage === 'sessionStorage') {
    _setCurrentUser(storageTokens.tokens, true, 'sessionStorage');
  }

  const getHomePage = () => {
    if (process.env.REACT_APP_VERSION === '0.0.1') {
      return ComingSoon;
    }

    return Home;
  };

  return (
    <>
      <Router history={appHistory}>
        <Suspense fallback={<SuspenseSpinner />}>
          <Switch>
            <Route exact path="/" component={getHomePage()} />
            <Route exact path="/coming-soon" component={ComingSoon} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Suspense>
      </Router>
      <ModalsContainer />
    </>
  );
};

interface RoutesProps {
  isAuth: boolean;
  _setCurrentUser: (
    tokens: Tokens,
    isAuth: boolean,
    storage: 'localStorage' | 'sessionStorage',
  ) => Promise<{ type: string; payload: { isAuth: boolean; user: UserI } }>;
  currentUser: RegistrationState['currentUser'];
}

const mapStateToProps = ({ registrationState: { isAuth, currentUser } }: StoreI) => ({
  isAuth,
  currentUser,
});

export default connect(mapStateToProps, {
  _setCurrentUser: setCurrentUser,
})(Routes);
