export interface ModalsState {
  currentModal: { type: ModalsOptions | null; minWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' };
}

export enum ModalsOptions {
  PASSWORD_RESET_EMAIL = 'PASSWORD_RESET_EMAIL',
  PASSWORD_RESET_VERIFICATION_CODE = 'PASSWORD_RESET_VERIFICATION_CODE',
  PASSWORD_RESET_NEW_PASSWORD = 'PASSWORD_RESET_NEW_PASSWORD',
  CONTACT_US = 'CONTACT_US',
}
