/* eslint-disable no-underscore-dangle,@typescript-eslint/naming-convention */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import store from '../store';
import { RegistrationActionTypes } from '../store/actions/types/registration';
import { storeTokensInLocalStorage, storeTokensInSessionStorage } from '@mola/client-components';

type RefreshWaiter = {
  resolve: (accessToken: string) => void;
  reject: (err: Error) => void;
};

const unprotectedUrls: string[] = ['/auth/login/'];

class Server {
  private readonly apiHandler: AxiosInstance;

  isRefreshing = false;

  refreshQueue = new Array<RefreshWaiter>();

  retries = 1;

  constructor(
    baseUrl = process.env.REACT_APP_BACKEND_URL,
    additionalAxiosHeaders?: { [key: string]: any },
    additionalAxiosSettings?: { [key: string]: any },
  ) {
    const initialHeaders: { [key: string]: any } =
      store.getState().registrationState.tokens.access !== ''
        ? {
            Authorization: `jwt ${store.getState().registrationState.tokens.access}`,
          }
        : {};

    this.apiHandler = axios.create({
      baseURL: baseUrl,
      headers: {
        ...initialHeaders,
        'Content-Type': 'application/json',
        ...additionalAxiosHeaders,
      },
      ...additionalAxiosSettings,
    });
  }

  private assignOriginsInterceptor() {
    this.apiHandler.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (config.headers) {
          // eslint-disable-next-line no-param-reassign

          config.headers['x-origin'] = process.env.REACT_APP_X_ORIGIN as string;
          config.headers['x-agent'] = process.env.REACT_APP_X_AGENT as string;
        }

        return config;
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  }

  private assignTokenInterceptor() {
    this.apiHandler.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (
          store.getState().registrationState.tokens.access !== '' &&
          !unprotectedUrls.includes(config.url as string) &&
          config.headers
        ) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `jwt ${store.getState().registrationState.tokens.access}`;
        }

        return config;
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  }

  get getMainBackendApiHandler() {
    this.assignTokenInterceptor();
    this.assignOriginsInterceptor();

    this.apiHandler.interceptors.response.use(undefined, async (err) => {
      if (!err.response) {
        return Promise.reject(err);
      }

      const { config: orgConfig, response } = err;

      const { status } = response;

      if (!unprotectedUrls.includes(orgConfig.url)) {
        if (status === 401) {
          orgConfig._retry = typeof orgConfig._retry === 'undefined' ? 0 : orgConfig._retry + 1;

          if (orgConfig._retry === this.retries) {
            return Promise.reject(err);
          }

          if (!this.isRefreshing) {
            this.isRefreshing = true;
            try {
              // do what waiters are waiting for
              const { accessToken } = await this.refreshToken();
              this.refreshQueue.forEach((waiter: RefreshWaiter) => {
                waiter.resolve(accessToken);
              });
              this.refreshQueue = [];
              (
                this.apiHandler.defaults.headers as { [key: string]: any }
              ).Authorization = `jwt ${accessToken}`;
              return await this.apiHandler(orgConfig);
            } catch (e) {
              // reject everything in the queue
              this.refreshQueue.forEach((v) => v.reject(err));
              this.refreshQueue = [];
              localStorage.setItem('redirect-path', window.location.pathname);
              store.dispatch({
                type: RegistrationActionTypes.TOGGLE_LOGIN_FORM,
                payload: true,
              });
            } finally {
              this.isRefreshing = false;
            }
          }

          return new Promise((resolve, reject) => {
            this.refreshQueue.push({
              resolve: (accessToken: string) => {
                (
                  this.apiHandler.defaults.headers as { [key: string]: any }
                ).Authorization = `jwt ${accessToken}`;
                return resolve(this.apiHandler(orgConfig));
              },
              reject: (error: Error) => {
                return reject(error);
              },
            });
          });
        }

        return Promise.reject(err);
      }
      return Promise.reject(err);
    });

    return this.apiHandler;
  }

  // eslint-disable-next-line class-methods-use-this
  async refreshToken() {
    const res = await axios.get<any>(`${process.env.REACT_APP_BACKEND_URL}/auth/token-refresh`, {
      headers: {
        authorization: `Bearer ${store.getState().registrationState.tokens.refresh}`,
        'x-origin': `${process.env.REACT_APP_X_ORIGIN}`,
        'x-agent': `${process.env.REACT_APP_X_AGENT}`,
      },
    });

    const {
      data: { refreshToken, accessToken },
    } = res.data;

    if (store.getState().registrationState.tokensStorage === 'localStorage') {
      storeTokensInLocalStorage(accessToken, refreshToken);
    } else {
      storeTokensInSessionStorage(accessToken, refreshToken);
    }

    store.dispatch({
      type: RegistrationActionTypes.SET_TOKENS_VALUE,
      payload: {
        access: accessToken,
        refresh: refreshToken,
      },
    });

    return { accessToken, refreshToken };
  }
}

const server = new Server().getMainBackendApiHandler;

export default server;
