import React, { useState } from 'react';
import { PasswordInput, TextInput } from '@mola/client-components';
import { ReactComponent as EyeXIcon } from '../../assets/svg/eye-x-icon.svg';
import { ReactComponent as EyeIcon } from '../../assets/svg/eye-icon.svg';

const PasswordReset: React.FC<PasswordResetProps> = ({ step, handleClose = () => {} }) => {
  const [localStep, setLocalStep] = useState(step);

  switch (localStep) {
    case 'email':
      return (
        <div className="px-10">
          <h2 className="font-bold text-xl py-6">Forgot Password?</h2>
          <p className="text-primary text-lg pb-2">Provide your email for recovery</p>
          <TextInput labelKey="Email" forText="email" isRequired classes={{}} placeHolder="email" />
          <button className="button w-full mt-6" onClick={() => setLocalStep('verification-code')}>
            Submit
          </button>
        </div>
      );
    case 'verification-code':
      return (
        <div className="px-10">
          <h2 className="font-bold text-xl py-6">Forgot Password?</h2>
          <p className="text-primary text-lg pb-2">Provide verification code sent to your email</p>
          <TextInput
            labelKey="Verification Code"
            forText="verification-code"
            isRequired
            classes={{}}
            placeHolder="Verification code"
          />
          <div className="flex flex-row justify-between pt-6">
            <span>Havent got the code?</span>
            <button className="text-primary" onClick={() => {}}>
              Resend the code
            </button>
          </div>
          <button className="button w-full mt-6" onClick={() => setLocalStep('password')}>
            Submit
          </button>
        </div>
      );
    case 'password':
      return (
        <div className="px-10">
          <h2 className="font-bold text-xl py-6">Forgot Password?</h2>
          <p className="text-primary text-lg pb-2">Set new password</p>
          <PasswordInput
            labelKey="New Password"
            forText="password"
            isRequired
            placeHolder="password"
            getEyeIcons={() => ({
              EyeIcon: <EyeIcon />,
              EyeXIcon: <EyeXIcon />,
            })}
          />
          <PasswordInput
            labelKey="Confirm Password"
            forText="password"
            isRequired
            placeHolder="password"
            classes={{
              container: 'mt-6',
            }}
          />
          <button className="button w-full mt-6" onClick={handleClose}>
            Submit
          </button>
        </div>
      );
  }
};

interface PasswordResetProps {
  step: 'email' | 'verification-code' | 'password';
  handleClose?: () => void;
}

export default PasswordReset;
