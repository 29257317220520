import { Reducer } from 'redux';
import initialState from '../initialState';
import { ContactActionTypes } from '../actions/types/contact';
import { ContactsState } from '../../@types/redux/appRessourceStates/contact';
import {
  SetContactActionI,
  SetIsLoadingAllContactsActionI,
  ContactsActions,
} from '../../@types/redux/actionCreators/contact';
export const contactsReducer: Reducer<ContactsState, ContactsActions> = (
  state = initialState.contactsState,
  action,
) => {
  switch (action.type) {
    case ContactActionTypes.SET_CONTACT:
      return {
        ...state,
        contact: (action as SetContactActionI).payload,
        isLoadingRequests: false,
      };

    case ContactActionTypes.SET_IS_LOADING_ALL_CONTACTS:
      return {
        ...state,
        isLoadingRequests: (action as SetIsLoadingAllContactsActionI).payload,
      };

    default:
      return state;
  }
};
