import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import {
  RegistrationErrorActionI,
  SetCurrentUserActionI,
  SetIsLoadingActionI,
  SetTokensStorageActionI,
} from '../../../@types/redux/actionCreators/registration';
import { RegistrationActionTypes } from '../types/registration';
import server from '../../../services/axios.service';
import store from '../../index';
import { setCurrentUserWithDispatch } from '../../../shared/helpers/redux';
import {
  decodeUser,
  Tokens,
  storeTokensInLocalStorage,
  storeTokensInSessionStorage,
} from '@mola/client-components';
import { appHistory } from '../../../shared/utils/sharedHistory';

export const setTokensStorage: ActionCreator<
  ThunkAction<
    { type: string; payload: string },
    { type: string; payload: string },
    'localStorage' | 'cookieStorage',
    SetTokensStorageActionI
  >
> = (storage: 'localStorage' | 'sessionStorage') => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.SET_TOKENS_STORAGE,
    payload: storage,
  });
};

export const login: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload:
        | {
            isAuth: boolean;
            user: UserI;
            storage: 'localStorage' | 'sessionStorage';
          }
        | string;
    }>,
    {
      type: string;
      payload:
        | {
            isAuth: boolean;
            user: UserI;
            storage: 'localStorage' | 'sessionStorage';
          }
        | string;
    },
    { email: string; password: string },
    SetCurrentUserActionI
  >
> = (formData: { email: string; password: string }) => async (dispatch: Dispatch) => {
  dispatch({
    type: RegistrationActionTypes.SET_IS_LOADING,
    payload: true,
  });

  try {
    const { data: result } = await server.post<
      ServerResponseT<
        Merge<
          UserI,
          { roles: string[]; accessToken: string; refreshToken: string; tokenType: string }
        >
      >
    >('/auth/login/', formData);

    dispatch({
      type: RegistrationActionTypes.SET_IS_LOADING,
      payload: false,
    });

    if (store.getState().registrationState.tokensStorage === 'localStorage') {
      storeTokensInLocalStorage(
        result.data?.accessToken as string,
        result.data?.refreshToken as string,
      );
    }

    if (store.getState().registrationState.tokensStorage === 'sessionStorage') {
      storeTokensInSessionStorage(
        result.data?.accessToken as string,
        result.data?.refreshToken as string,
      );
    }

    dispatch({
      type: RegistrationActionTypes.SET_TOKENS_VALUE,
      payload: {
        access: result.data?.accessToken,
        refresh: result.data?.refreshToken,
      },
    });

    // Redirecting in case it is set in the local storage
    const redirectPath = localStorage.getItem('redirect-path');
    const decodedUser = decodeUser(result.data?.accessToken as string);

    if (redirectPath) {
      if (window.location.href !== redirectPath) {
        appHistory.push(redirectPath);
      }
      localStorage.removeItem('redirect-path');
    }

    return setCurrentUserWithDispatch(
      dispatch,
      decodedUser,
      true,
      store.getState().registrationState.tokensStorage,
    );
  } catch (error: any) {
    if (
      error.response?.data?.status === 400 &&
      error.response?.data?.data?.message === 'Invalid email or password'
    ) {
      return dispatch({
        type: RegistrationActionTypes.REGISTRATION_ERROR,
        payload: error.response?.data?.data?.message,
      });
    }

    return dispatch({
      type: RegistrationActionTypes.REGISTRATION_ERROR,
      payload: 'Something went wrong',
    });
  }
};

export const setCurrentUser: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: {
        isAuth: boolean;
        user: UserI;
        storage: 'localStorage' | 'sessionStorage';
      };
    }>,
    { [key: string]: any },
    string,
    SetCurrentUserActionI
  >
> =
  (tokens: Tokens, isAuth: boolean, storage: 'localStorage' | 'sessionStorage') =>
  (dispatch: Dispatch) => {
    dispatch({
      type: RegistrationActionTypes.SET_TOKENS_VALUE,
      payload: tokens,
    });
    return Promise.resolve(
      setCurrentUserWithDispatch(dispatch, decodeUser(tokens.access), isAuth, storage),
    );
  };

export const setErrorMessage: ActionCreator<
  ThunkAction<
    { type: string; payload: string | null },
    { [key: string]: any },
    string,
    RegistrationErrorActionI
  >
> = (errorMessage: string | null) => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.REGISTRATION_ERROR,
    payload: errorMessage,
  });
};

export const setIsLoading: ActionCreator<
  ThunkAction<
    { type: string; payload: boolean },
    { [key: string]: any },
    boolean,
    SetIsLoadingActionI
  >
> = (isLoading: boolean) => (dispatch: Dispatch) => {
  return dispatch({
    type: RegistrationActionTypes.SET_IS_LOADING,
    payload: isLoading,
  });
};

export const logout: ActionCreator<ThunkAction<void, void, boolean, any>> =
  () => (dispatch: Dispatch) => {
    // Initializing redux
    dispatch({
      type: RegistrationActionTypes.LOGOUT,
    });
  };
