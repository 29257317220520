import React from 'react';
import { Formik, Form } from 'formik';

import ContactMap from '../../../assets/img/Maps-Address.png';
import { contactValidationSchema } from '../../../shared/schemas/contact-us';
import { connect } from 'react-redux';
import { SetContact } from '../../../store/actions/creators/contact';
import { InputChangeState, TextAreaInput, TextInput } from '@mola/client-components';

const ContactUs: React.FC<ContactProps> = ({ _setContact, isLoadingContact }) => {
  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      }}
      validateOnChange
      validationSchema={contactValidationSchema}
      onSubmit={(values) => {
        _setContact({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          message: values.message,
        });
      }}
    >
      {(props) => (
        <div className="contact-us ">
          <div className="flex flex-column">
            <div className="w-1/2">
              <img src={ContactMap} alt="Map" style={{ width: '100%' }} />
            </div>
            <div className="w-1/2 p-8">
              <h1 className="text-center font-bold text-dark w-full">Get In touch</h1>
              <Form className="px-2">
                <div className="flex flex-column justify-between ">
                  <TextInput
                    labelKey="First Name"
                    forText="firstName"
                    name="firstName"
                    value={props.values.firstName}
                    onChange={(value, event) => {
                      props.handleChange(event);
                    }}
                    changeState={
                      props.touched.firstName && props.errors.firstName
                        ? InputChangeState.INVALID
                        : InputChangeState.VALID
                    }
                    errorMessage={props.errors.firstName}
                    isRequired
                    disabled={isLoadingContact}
                    placeHolder="Your Name"
                  />
                  <TextInput
                    labelKey="Last Name"
                    forText="lastName"
                    name="lastName"
                    value={props.values.lastName}
                    onChange={(value, event) => {
                      props.handleChange(event);
                    }}
                    changeState={
                      props.touched.lastName && props.errors.lastName
                        ? InputChangeState.INVALID
                        : InputChangeState.VALID
                    }
                    errorMessage={props.errors.lastName}
                    isRequired
                    disabled={isLoadingContact}
                    placeHolder="Your Name"
                  />
                </div>
                <TextInput
                  labelKey="Your Email"
                  forText="email"
                  name="email"
                  value={props.values.email}
                  onChange={(value, event) => {
                    props.handleChange(event);
                  }}
                  changeState={
                    props.touched.email && props.errors.email
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  errorMessage={props.errors.email}
                  isRequired
                  disabled={isLoadingContact}
                  placeHolder="email"
                />
                <TextAreaInput
                  labelKey="Message"
                  forText="message"
                  name="message"
                  value={props.values.message}
                  onChange={(value, event) => {
                    props.handleChange(event);
                  }}
                  changeState={
                    props.touched.message && props.errors.message
                      ? InputChangeState.INVALID
                      : InputChangeState.VALID
                  }
                  disabled={isLoadingContact}
                  errorMessage={props.errors.message}
                  isRequired
                  placeHolder="Message"
                />
                <button className="button w-full mt-6 " type="submit" disabled={isLoadingContact}>
                  Send Message
                </button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

interface ContactProps {
  _setContact: (contact: ContactI) => Promise<{
    type: string;
    payload: FacilityI | null;
  }>;
  isLoadingContact: boolean;
}

const mapStateToProps = ({ contactsState: { isLoadingContacts, contact } }: StoreI) => ({
  isLoadingContact: isLoadingContacts,
  contact,
});

export default connect(mapStateToProps, {
  _setContact: SetContact,
})(ContactUs);
