const initialState: StoreI = {
  modalsState: {
    currentModal: { minWidth: 'sm', type: null },
  },
  registrationState: {
    isAuth: false,
    currentUser: null,
    tokens: {
      access: '',
      passwordReset: '',
      refresh: '',
    },
    errorMessage: null,
    isLoading: false,
    message: null,
    tokensStorage: 'sessionStorage',
  },
  contactsState: {
    contact: null,
    isLoadingContacts: false,
  },
  uiState: { errorMessage: '' },
};

export default initialState;
