import { ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import server from '../../../services/axios.service';
import { UiActionTypes } from '../types/ui';
import { SetContactActionI } from '../../../@types/redux/actionCreators/contact';
import { ContactActionTypes } from '../types/contact';
import { toast } from 'react-toastify';

export const SetContact: ActionCreator<
  ThunkAction<
    Promise<{
      type: string;
      payload: ContactI | string;
    }>,
    {
      type: string;
      payload: ContactI | string;
    },
    null,
    SetContactActionI
  >
> = (contact?: ContactI) => async (dispatch: Dispatch) => {
  dispatch({
    type: ContactActionTypes.SET_IS_LOADING_ALL_CONTACTS,
    payload: true,
  });

  try {
    const res = await server.post<ContactI>(`/support/message`, contact);

    toast.success('Message sent successfully !', { autoClose: 3000 });

    return dispatch({
      type: ContactActionTypes.SET_CONTACT,
      payload: res.data,
    });
  } catch (e) {
    return dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong',
    });
  }
};

export const addUserToWaitList = (email: string) => async (dispatch: Dispatch) => {
  try {
    await server.post(`/support/app-wait-list`, { email });

    toast.success('Email saved successfully. We will contact you soon', { autoClose: 3000 });
  } catch (e) {
    dispatch({
      type: UiActionTypes.SET_CURRENT_ERROR,
      payload: 'Something went wrong',
    });
  }
};
