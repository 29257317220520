import * as Yup from 'yup';

export const contactValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(5, 'Please provide a valid first name')
    .required('Please provide a First Name'),
  lastName: Yup.string()
    .min(5, 'Please provide a valid last name')
    .required('Please provide a Last Name'),
  email: Yup.string().email('Please provide a valid email').required('Please provide an email'),
  message: Yup.string()
    .min(10, 'Message must be at least 10 characters long')
    .required('Please provide a message'),
});
